import React from 'react';
import Layout from '../../components/App';
import ConnectedLogin from './../../routes/login/Login';
import { useRouter } from 'next/router';

export default function LoginPage() {
  const router = useRouter();
  const url = tryBase64Decode(router.query.redirect_url as string);

  return (
    <Layout title={'Login'} hideNav={true} fullWidth={true} hideTitle={true}>
      <ConnectedLogin redirectUrl={url} />
    </Layout>
  );
}

const tryBase64Decode = (value: string | undefined): string | undefined => {
  if (!value) {
    return undefined;
  }
  try {
    return atob(value);
  } catch (ex) {
    return value;
  }
};
